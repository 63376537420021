import React from 'react';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import FdrThemeGeoPageComponentDefault from './fdr-theme-geo-page-component-default';
import FdrThemeGeoPageComponentSwipe from './fdr-theme-geo-page-component-swipe';

interface IFdrThemeGeoPageComponentProps {
  component: Extract<
    FdrPageComponentsFragment['components'][number],
    { __typename: 'FdrThemeGeoRecommender' }
  >;
}

const FdrThemeGeoPageComponent: React.FC<IFdrThemeGeoPageComponentProps> = ({
  component,
}) => {
  if (component.displayType === 'SWIPE') {
    return (
      <FdrThemeGeoPageComponentSwipe
        id={component.id}
        title={component.title}
        items={component.itemsPage.items.map(themeGeo => ({
          ...themeGeo.theme,
          link: themeGeo.webMeta.link,
        }))}
        bgColor={component.bgColor}
        fontColor={component.fgColor}
        pagination={component.itemsPage.pagination}
      />
    );
  }

  return (
    <FdrThemeGeoPageComponentDefault
      id={component.id}
      title={component.title}
      items={component.itemsPage.items.map(themeGeo => ({
        ...themeGeo.geo,
        link: themeGeo.webMeta.link,
      }))}
      bgColor={component.bgColor}
      fontColor={component.fgColor}
      pagination={component.itemsPage.pagination}
    />
  );
};

export default FdrThemeGeoPageComponent;
