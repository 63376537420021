import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrHeroMediaGalleryFragmentDoc } from 'schemas/fragment/hero-media-gallery/fdr-hero-media-gallery.generated';
import { FdrMediaGalleryFragmentDoc } from 'schemas/fragment/media-gallery/fdr-media-gallery.generated';
import { FdrMarketingRecommenderFragmentDoc } from 'schemas/fragment/regular/fdrMarketingRecommender.generated';
import { FdrBlogArticleRecommenderFragmentDoc } from 'schemas/fragment/recommenders/blog-article/blog-article-recommneder.generated';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import { FdrAgencyRecommenderFragmentDoc } from 'schemas/fragment/recommenders/agency/fdr-agency-recommender.generated';
import { FdrGeoRecommenderFragmentDoc } from 'schemas/fragment/recommenders/geo/fdr-geo-recommender.generated';
import { FdrThemeGeoRecommenderFragmentDoc } from 'schemas/fragment/recommenders/theme-geo/fdr-theme-geo-recommender.generated';
import { FdrProductRecommenderFragmentDoc } from 'schemas/fragment/recommenders/product/fdr-product-recommender.generated';
import { FdrThemeRecommenderFragmentDoc } from 'schemas/fragment/recommenders/theme/fdr-theme-recommender.generated';
import {
  FdrMarkdownTextFragmentDoc,
  FdrRawTextFragmentDoc,
} from 'schemas/fragment/regular/fdrText.generated';
import { FdrUspBoxesFragmentDoc } from 'schemas/fragment/usp-boxes/fdr-usp-boxes.generated';
import { FdrFaqFragmentDoc } from 'schemas/fragment/faq/fdr-faq.generated';
import { FdrBenefitsFragmentDoc } from 'schemas/fragment/benefits/fdr-benefits.generated';
import { FdrContactModuleFragmentDoc } from 'schemas/fragment/contact-module/fdr-contact-module.generated';
export type FdrPageComponentsFragment = {
  __typename?: 'FdrPageComponents';
  components?: Array<
    | { __typename: 'FdrAccordion' }
    | { __typename: 'FdrAccordionComponent' }
    | { __typename: 'FdrAgencyContactModule' }
    | {
        __typename: 'FdrAgencyRecommender';
        id: string;
        title?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        agencies: {
          __typename?: 'FdrAgencyRecommenderAgencies';
          agenciesPage?: Array<{
            __typename?: 'FdrAgency';
            name: string;
            openStatus: Types.FdrAgencyOpenStatus;
            address?: {
              __typename?: 'FdrAgencyAddress';
              zip?: string | null;
              city?: string | null;
              region?: string | null;
              street?: string | null;
              country?: string | null;
            } | null;
            image?: {
              __typename?: 'FdrImage';
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          }> | null;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
        };
      }
    | {
        __typename: 'FdrBenefitsComponent';
        id: string;
        text?: string | null;
        buttonLabel: string;
        buttonLink: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
        benefitItems: Array<{ __typename?: 'FdrBenefitItem'; text: string }>;
      }
    | {
        __typename: 'FdrBlogArticleRecommender';
        id: string;
        title?: string | null;
        blogArticleItems: {
          __typename?: 'FdrAllBlogArticlesResponse';
          blogArticles?: Array<{
            __typename?: 'FdrBlogArticle';
            publicId: string;
            title?: string | null;
            text?: string | null;
            start?: any | null;
            flagline?: string | null;
            webMeta: {
              __typename?: 'FdrMeta';
              title: string;
              description: string;
              index: boolean;
              noFollow: boolean;
              sitemap: boolean;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
            hero?: {
              __typename?: 'FdrImage';
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            employee?: {
              __typename?: 'FdrEmployee';
              name: string;
              image?: {
                __typename?: 'FdrImage';
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
            } | null;
          }> | null;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
        };
      }
    | { __typename: 'FdrBrandsComponent' }
    | {
        __typename: 'FdrContactModule';
        id: string;
        flagline?: string | null;
        title?: string | null;
        moduleItems: Array<
          | {
              __typename?: 'FdrContactModuleLink';
              title?: string | null;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
              icon?: {
                __typename?: 'FdrImage';
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
            }
          | {
              __typename?: 'FdrContactModulePhone';
              phone?: string | null;
              title?: string | null;
              icon?: {
                __typename?: 'FdrImage';
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
            }
        >;
      }
    | { __typename: 'FdrEmployeesComponent' }
    | {
        __typename: 'FdrFaq';
        bgColor?: string | null;
        fgColor?: string | null;
        answer: string;
        question: string;
      }
    | { __typename: 'FdrFlightOfferRecommender' }
    | { __typename: 'FdrFlightRecommender' }
    | { __typename: 'FdrGeoChildrenPlaceholder' }
    | { __typename: 'FdrGeoClimatePlaceholder' }
    | { __typename: 'FdrGeoGalleryPlaceholder' }
    | { __typename: 'FdrGeoLeadTextPlaceholder' }
    | { __typename: 'FdrGeoLongTextPlaceholder' }
    | { __typename: 'FdrGeoMapPlaceholder' }
    | { __typename: 'FdrGeoOfferButtonPlaceholder' }
    | { __typename: 'FdrGeoOfferRecommender' }
    | {
        __typename: 'FdrGeoRecommender';
        id: string;
        title?: string | null;
        fgColor?: string | null;
        bgColor?: string | null;
        itemsPage: {
          __typename?: 'FdrGeoRecommenderItem';
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
          items?: Array<
            | {
                __typename: 'FdrContinent';
                id: string;
                title: string;
                webMeta: {
                  __typename?: 'FdrGeoWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename: 'FdrCountry';
                id: string;
                title: string;
                webMeta: {
                  __typename?: 'FdrGeoWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename: 'FdrCountryGroup';
                id: string;
                title: string;
                webMeta: {
                  __typename?: 'FdrGeoWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename: 'FdrDestination';
                id: string;
                title: string;
                webMeta: {
                  __typename?: 'FdrGeoWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename: 'FdrRegion';
                id: string;
                title: string;
                webMeta: {
                  __typename?: 'FdrGeoWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename: 'FdrResort';
                id: string;
                title: string;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
          > | null;
        };
      }
    | { __typename: 'FdrGeoRecommenderPlaceholder' }
    | {
        __typename: 'FdrHeroMediaGallery';
        title?: string | null;
        titleColor?: string | null;
        flagline?: string | null;
        media: Array<
          | { __typename: 'FdrAudio' }
          | { __typename: 'FdrExternalMedia' }
          | {
              __typename: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            }
          | { __typename: 'FdrPdf' }
          | {
              __typename: 'FdrVideo';
              copyright?: string | null;
              width?: number | null;
              height?: number | null;
              preview?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                mediaPreviewResized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              resourceLink: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }
        >;
      }
    | { __typename: 'FdrImageText' }
    | { __typename: 'FdrLinkList' }
    | {
        __typename: 'FdrMarkdownText';
        id: string;
        bgColor?: string | null;
        fgColor?: string | null;
        text?: string | null;
        topPadding?: string | null;
        bottomPadding?: string | null;
        width?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
      }
    | {
        __typename: 'FdrMarketingRecommender';
        id: string;
        fgColor?: string | null;
        titleFgColor?: string | null;
        bgColor?: string | null;
        title?: string | null;
        displayType: string;
        marketingItems: Array<{
          __typename?: 'FdrMarketingRecommenderItem';
          title?: string | null;
          subtitle: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
          media?:
            | {
                __typename?: 'FdrAudio';
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
                preview?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrExternalMedia';
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
                preview?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                resizedPreview: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
                resizedImage: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              }
            | {
                __typename?: 'FdrPdf';
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
                preview?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrVideo';
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
                preview?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | null;
        }>;
      }
    | { __typename: 'FdrMarketingTeaser' }
    | {
        __typename: 'FdrMediaGallery';
        bgColor?: string | null;
        fgColor?: string | null;
        media: Array<
          | {
              __typename: 'FdrAudio';
              title?: string | null;
              preview?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                mediaPreviewResized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              resourceLink: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }
          | {
              __typename: 'FdrExternalMedia';
              title?: string | null;
              preview?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                mediaPreviewResized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              resourceLink: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }
          | {
              __typename: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              mediaImageResized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
              mediaImagePreviewResized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                latitude: number;
                longitude: number;
              } | null;
            }
          | {
              __typename: 'FdrPdf';
              caption: string;
              preview?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                mediaPreviewResized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }
          | {
              __typename: 'FdrVideo';
              copyright?: string | null;
              width?: number | null;
              height?: number | null;
              preview?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                mediaPreviewResized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              resourceLink: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }
        >;
      }
    | { __typename: 'FdrNewsArticlesRecommender' }
    | { __typename: 'FdrNewsletterSignup' }
    | { __typename: 'FdrProductOfferRecommender' }
    | { __typename: 'FdrProductOffersMap' }
    | {
        __typename: 'FdrProductRecommender';
        id: string;
        title?: string | null;
        fgColor?: string | null;
        bgColor?: string | null;
        productPage: {
          __typename?: 'FdrProductRecommenderPage';
          products?: Array<
            | {
                __typename: 'FdrAdventureTravel';
                name: string;
                teaser: string;
                id: string;
                bookmarked: boolean;
                images?: Array<{
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                }> | null;
                disrupters?: Array<{
                  __typename?: 'FdrDisrupter';
                  text: string;
                  bgColor?: string | null;
                  fgColor?: string | null;
                }> | null;
                route?: Array<{
                  __typename?: 'FdrAdventureTravelRoutePoint';
                  locationName: string;
                  coordinates: {
                    __typename?: 'FdrCoordinates';
                    latitude: number;
                    longitude: number;
                  };
                }> | null;
                webMeta: {
                  __typename?: 'FdrProductWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
                featureGroups?: Array<{
                  __typename?: 'FdrProductFeatureGroup';
                  id: string;
                  name: string;
                  features: Array<{
                    __typename?: 'FdrProductFeature';
                    id: string;
                    name: string;
                    enabled: boolean;
                  }>;
                }> | null;
              }
            | { __typename: 'FdrCruise' }
            | {
                __typename: 'FdrHotel';
                name: string;
                id: string;
                bookmarked: boolean;
                images?: Array<{
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                }> | null;
                disrupters?: Array<{
                  __typename?: 'FdrDisrupter';
                  text: string;
                  bgColor?: string | null;
                  fgColor?: string | null;
                }> | null;
                webMeta: {
                  __typename?: 'FdrProductWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
              }
            | {
                __typename: 'FdrRoundTrip';
                name: string;
                mode?: string | null;
                giataCode?: number | null;
                leadText?: string | null;
                duration: string;
                id: string;
                bookmarked: boolean;
                images?: Array<{
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                }> | null;
                disrupters?: Array<{
                  __typename?: 'FdrDisrupter';
                  text: string;
                  bgColor?: string | null;
                  fgColor?: string | null;
                }> | null;
                webMeta: {
                  __typename?: 'FdrProductWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
                featureGroups?: Array<{
                  __typename?: 'FdrProductFeatureGroup';
                  id: string;
                  name: string;
                  features: Array<{
                    __typename?: 'FdrProductFeature';
                    id: string;
                    name: string;
                    enabled: boolean;
                  }>;
                }> | null;
              }
          > | null;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
        };
      }
    | {
        __typename: 'FdrRawText';
        id: string;
        bgColor?: string | null;
        fgColor?: string | null;
        text?: string | null;
        topPadding?: string | null;
        bottomPadding?: string | null;
        width?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
      }
    | { __typename: 'FdrRssBlogArticleRecommender' }
    | { __typename: 'FdrSmartSeerRecommender' }
    | { __typename: 'FdrSrlButton' }
    | {
        __typename: 'FdrThemeGeoRecommender';
        id: string;
        title?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        displayType: string;
        itemsPage: {
          __typename?: 'FdrThemeGeoRecommenderItems';
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
          items?: Array<{
            __typename?: 'FdrThemeGeoPage';
            title: string;
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            geo:
              | {
                  __typename: 'FdrContinent';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrCountry';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrCountryGroup';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrDestination';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrRegion';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrResort';
                  id: string;
                  title: string;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                };
            theme: {
              __typename?: 'FdrTheme';
              id: string;
              title: string;
              image?: {
                __typename?: 'FdrImage';
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              webMeta: {
                __typename?: 'FdrThemeWebMeta';
                descriptionWebMeta?: {
                  __typename?: 'FdrMeta';
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                } | null;
              };
            };
            webMeta: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          }> | null;
        };
      }
    | { __typename: 'FdrThemeOfferRecommender' }
    | {
        __typename: 'FdrThemeRecommender';
        id: string;
        title?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        displayType: string;
        themePage: {
          __typename?: 'FdrThemeRecommenderPage';
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
          themes?: Array<{
            __typename?: 'FdrTheme';
            id: string;
            title: string;
            image?: {
              __typename?: 'FdrImage';
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
            webMeta: {
              __typename?: 'FdrThemeWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
          }> | null;
        };
      }
    | {
        __typename: 'FdrUspBoxesComponent';
        id: string;
        bgColor?: string | null;
        fgColor?: string | null;
        boxes: Array<{
          __typename?: 'FdrUspBox';
          title: string;
          text?: string | null;
          subtitle?: string | null;
          icon?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
        }>;
      }
  > | null;
  pagination: {
    __typename?: 'FdrPagination';
    total: number;
    page: number;
    perPage: number;
  };
};

export const FdrPageComponentsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrPageComponents' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrPageComponents' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'components' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrHeroMediaGallery' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrMediaGallery' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrMarketingRecommender' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrBlogArticleRecommender' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAgencyRecommender' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrGeoRecommender' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrThemeGeoRecommender' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductRecommender' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrThemeRecommender' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrMarkdownText' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrRawText' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrUspBoxes' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFaq' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrBenefits' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrContactModule' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pagination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPagination' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHeroMediaGalleryFragmentDoc.definitions,
    ...FdrMediaGalleryFragmentDoc.definitions,
    ...FdrMarketingRecommenderFragmentDoc.definitions,
    ...FdrBlogArticleRecommenderFragmentDoc.definitions,
    ...FdrAgencyRecommenderFragmentDoc.definitions,
    ...FdrGeoRecommenderFragmentDoc.definitions,
    ...FdrThemeGeoRecommenderFragmentDoc.definitions,
    ...FdrProductRecommenderFragmentDoc.definitions,
    ...FdrThemeRecommenderFragmentDoc.definitions,
    ...FdrMarkdownTextFragmentDoc.definitions,
    ...FdrRawTextFragmentDoc.definitions,
    ...FdrUspBoxesFragmentDoc.definitions,
    ...FdrFaqFragmentDoc.definitions,
    ...FdrBenefitsFragmentDoc.definitions,
    ...FdrContactModuleFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
