import React, { useCallback } from 'react';
import { FdrPagination } from '@hotelplan/supergraph-api';
import { TCommonRecommenderItem } from 'components/domain/fdr-page-components/fdr-page-components.types';
import {
  createPageComponentVariables,
  mapFdrPageComponentsResponseToThemeGeoWithGeoFallback,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import { FdrCommonGridRecommender } from 'components/domain/fdr-page-components/recommenders/common/fdr-common-grid-recommender';
import { FdrGeoRecommenderItem } from 'components/domain/fdr-page-components/recommenders/geo/fdr-geo-recommender-item';
import useFdrPageComponentsFetch from 'components/domain/fdr-page-components/useFdrPageComponentsFetch';
import { FdrThemeGeoComponentsDocument } from 'schemas/query/theme-geo/fdr-paginated-theme-geo-recommender.generated';

interface IFdrThemeGeoPageComponentDefaultProps {
  id: string;
  items: TCommonRecommenderItem[];
  bgColor?: string;
  fontColor?: string;
  title?: string;
  pagination?: FdrPagination;
}

const FdrThemeGeoPageComponentDefault: React.FC<IFdrThemeGeoPageComponentDefaultProps> =
  ({ id, items, bgColor, fontColor, title, pagination }) => {
    const mapVariables = useCallback(
      (page, perPage) => {
        return createPageComponentVariables(id, page, perPage);
      },
      [id]
    );

    const { fetchItems } = useFdrPageComponentsFetch(
      FdrThemeGeoComponentsDocument,
      mapVariables,
      mapFdrPageComponentsResponseToThemeGeoWithGeoFallback
    );

    return (
      <FdrCommonGridRecommender
        key={`geo-rec-section-${id}`}
        bgColor={bgColor}
        fontColor={fontColor}
        title={title}
        items={items}
        pagination={pagination}
        recommendationItem={FdrGeoRecommenderItem}
        fetchItems={fetchItems}
      />
    );
  };

export default FdrThemeGeoPageComponentDefault;
