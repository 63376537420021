import React, { useCallback } from 'react';
import { FdrPagination } from '@hotelplan/supergraph-api';
import { TCommonRecommenderItem } from 'components/domain/fdr-page-components/fdr-page-components.types';
import {
  createPageComponentVariables,
  mapFdrPageComponentsResponseToThemeGeoWithThemeFallback,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import { FdrThemeRecommender } from 'components/domain/fdr-page-components/recommenders/theme/fdr-theme-recommender';
import useFdrPageComponentsFetch from 'components/domain/fdr-page-components/useFdrPageComponentsFetch';
import { FdrThemeGeoComponentsDocument } from 'schemas/query/theme-geo/fdr-paginated-theme-geo-recommender.generated';

interface IFdrThemeGeoPageComponentSwipeProps {
  id: string;
  items: TCommonRecommenderItem[];
  bgColor?: string;
  fontColor?: string;
  title?: string;
  pagination?: FdrPagination;
}

const FdrThemeGeoPageComponentSwipe: React.FC<IFdrThemeGeoPageComponentSwipeProps> =
  ({ id, items, bgColor, fontColor, title, pagination }) => {
    const mapVariables = useCallback(
      (page, perPage) => {
        return createPageComponentVariables(id, page, perPage);
      },
      [id]
    );

    const { fetchItems } = useFdrPageComponentsFetch(
      FdrThemeGeoComponentsDocument,
      mapVariables,
      mapFdrPageComponentsResponseToThemeGeoWithThemeFallback
    );

    return (
      <FdrThemeRecommender
        total={pagination.total}
        title={title}
        id={id}
        bgColor={bgColor}
        fontColor={fontColor}
        items={items}
        displayType={'SWIPE'}
        fetchItems={fetchItems}
      />
    );
  };

export default FdrThemeGeoPageComponentSwipe;
